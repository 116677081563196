import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxMultiSection from '../../templates/mdx-multi-section.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const NavSecondary = makeShortcode("NavSecondary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxMultiSection;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <a id="living"></a>
    <section className="main style1">
      <header className="medium">
        <h2 {...{
          "id": "living-systems-collaboratory"
        }}>{`Living Systems Collaboratory`}</h2>
        <p>{`In the `}<strong parentName="p">{`2020-21 Living Systems Art + Design Collaboratory`}</strong>{`, a group of 26 emerging artists and designers developed creative projects to aid people in perceiving the biological, social, ecological, philosophical, spiritual, mathematical, political and technological dimensions of life as a unified whole, inspired by living systems, and considering both nonlinear and linear relationships. The program culminated in a series of online presentation where the participants presented and discussed their work.`}</p>
        <a href="https://www.designscience.studio/living-systems-collaboratory-launch-week-presentations" className="button large text-uppercase">Watch the Presentations</a>
      </header>
      <header>
        <h3>2020-21 Collaboratory Participants</h3>
        <iframe className="airtable-embed" src="https://airtable.com/embed/shregqqG6us7dESU2?backgroundColor=cyan" frameBorder="0" onmousewheel="" width="100%" height="759" style={{
          "background": "transparent",
          "border": "1px solid #ccc"
        }}></iframe>
SCROLL TO VIEW ALL 26 PARTICIPANTS
      </header>
      <header>
        <h3 {...{
          "id": "about-the-collaboratory"
        }}>{`About the Collaboratory`}</h3>
        <div className="inner special">
          <p>{`UC Irvine's `}<a parentName="p" {...{
              "href": "/#about"
            }}>{`Embodied Media Research Group`}</a>{` (EMRG) partnered with the `}<a parentName="p" {...{
              "href": "https://www.designscience.studio"
            }}>{`Design Science Studio`}</a>{` (DSS), a program of the `}<a parentName="p" {...{
              "href": "https://www.bfi.org"
            }}>{`Buckminster Fuller Institute`}</a>{` and `}<a parentName="p" {...{
              "href": "https://www.habritual.studio/"
            }}>{`habRitual`}</a>{`, to operate the Living Systems Art + Design Collaboratory* from November 2020 through March 2021. The program was structured in three phases as outlined below. During each phase, creators received financial support linked to their progress.`}</p>
          <div className="row">
  <div className="col-4 col-12-small">
    <h4>Incubator</h4>
    <p>
      In the <b>Incubator</b> phase, creators developed their initial proposal into a proof-of-concept prototype, Working within an iterative design framework facilitated by DSS and EMRG. Creators received a start-up financial award upon acceptance.
    </p>
  </div>
  <div className="col-4 col-12-small">
    <h4>Development</h4>
    <p>
      In the <b>Development</b> phase, selected Incubator projects were supported for further exploration and development into fully-realized form, again mentored by DSS and EMRG. Creators selected for this phase received an additional financial award.
    </p>
  </div>
  <div className="col-4 col-12-small">
    <h4>Presentation</h4>
    <p>
      In the <b>Presentation</b> phase, completed projects from the Development phase were polished for online exhibition. Creators selected for this phase received an additional financial award linked to the public presentation of their project.
    </p>
  </div>
          </div>
        </div>
        <div className="inner special" style={{
          paddingTop: "3rem"
        }}>
          <h3 {...{
            "id": "living-systems-philosophy"
          }}>{`Living Systems Philosophy`}</h3>
          <p><strong parentName="p">{`Participants responded to the theme: "everything is a system."`}</strong></p>
          <p>{`Humans occupy a self-organizing living system (our planet) with an inherent capability to sustain life. `}<strong parentName="p">{`Living systems`}</strong>{` are cognitive entities with an inherent tendency to structure themselves in `}<strong parentName="p">{`nonlinear`}</strong>{` network patterns capable of performing highly complex functions with minimum effort. The nonlinear nature of the digital era is an indication of the `}<strong parentName="p">{`emergence`}</strong>{` of our planet's ongoing processes of self-organizing and self-awareness. For humanity to synchronize with this process we can look to `}<strong parentName="p">{`systems theory`}</strong>{` and `}<strong parentName="p">{`network theory`}</strong>{` (which provide the essential and universal behavioral patterns of living systems) as guidelines for harmonious and creative participation with our  planet. There is a deeper simplicity within the living world. Human beings are an `}<strong parentName="p">{`interconnected`}</strong>{` and `}<strong parentName="p">{`inseparable`}</strong>{` part of this nonlinear living system, but too often we employ linear thinking strategies that risk making the human network unsustainable.`}</p>
        </div>
      </header>
    </section>
    <a id="background"></a>
    <section className="main style2">
      <header className="medium">
        <h2 {...{
          "id": "background"
        }}>{`Background`}</h2>
        <p>{`As Buckminster Fuller reminded us, we live in an “eternally regenerative Universe.” Living systems are regenerative by nature. A design scientist taps into the regenerative capability of ecosystems to discover elegant solutions. Regenerative design offers a framework in which humans are part of a larger, living whole. Regenerative development is a process that focuses on the ability of human beings to co-evolve with nature so that our planetary home continues to express its potential for diversity, complexity and creativity. When we understand the principles of regeneration, we celebrate life.`}</p>
      </header>
      <div className="inner special medium">
        <h3 {...{
          "id": "references"
        }}>{`References`}</h3>
        <ul className="none">
  <li><a href="http://digital-age-transformation-era.com">The Living Universe by Mansoor Vakili</a></li>
  <li><a href="https://books.google.com/books?id=3OAIaUZQhM0C">The Web of Life by Fritjof Capra</a></li>
        </ul>
        <hr />
        <div className="row">
  <div className="col-4 col-12-small">
    <p>
      <b>Presented by</b> <br /> 
      <b><a href="/">EMRG</a></b>
    </p>
  </div>
  <div className="col-4 col-12-small">
    <p>
      <b>Developed in partnership with</b> <br /> 
      <b><a href="https://habritual.studio">habRitual</a></b>
    </p>
  </div>
  <div className="col-4 col-12-small">
    <p>
      <b>Presented by</b> <br /> 
      <b><a href="https://bfi.org">Buckminster Fuller Institute</a></b>
    </p>
  </div>
        </div>
      </div>
      <footer>
  <div className="row aln-center gtr-50 gtr-uniform">
    <NavSecondary mdxType="NavSecondary" />
  </div>
  <div className="row">
    <div className="col-12 footnote">
        Image Credit: dbvirago
    </div>
  </div>
      </footer>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      